import { Heading, Image, SimpleGrid, Stack, Tab, TabList, TabPanel, TabPanels, Tabs, Tag, Text, VStack } from '@chakra-ui/react';

import AddTaskPage from '../theme/assets/add-task-page.png';
import BankingDetailsUpdate from '../theme/assets/banking-details-update.png';
import DashboardImage from '../theme/assets/citation-dashboard.png';
import InvoicePage from '../theme/assets/invoice-page.png';
import PaidAmount from '../theme/assets/paid-amount.png';
import PotentialAmount from '../theme/assets/potential-amount.png';
import React from 'react';
import TaskPage from '../theme/assets/task-page.png';

export const Features = (props: { id?: string; }) => {
    const { id } = props;
    return (
        <Stack py={8} alignItems={'center'} bg={'#f8f9fd'} id={id || 'features-section'}>
            <VStack py={6}>
                <Tag bgColor={'#ecfed4'} my={4} p={2} borderRadius={12}>Features</Tag>
                <Heading fontWeight={'regular'}>All your referal modules in one app</Heading>
                <Text maxW={'450px'} textAlign={'center'} color={'gray.500'} my={3}>We have a range of price plans to match your needs, so you can start small and scale up.</Text>

                <Tabs align='center' variant='soft-rounded' colorScheme='green' mt={6}>
                    <TabList bgColor={'white'} borderRadius={'full'} p={2} maxW={'580px'}>
                        <Tab _selected={{ bg: 'green.800', color: 'white', fontWeight: 'normal' }}>Reporting management</Tab>
                        <Tab _selected={{ bg: 'green.800', color: 'white', fontWeight: 'normal' }}>Payment account</Tab>
                        <Tab _selected={{ bg: 'green.800', color: 'white', fontWeight: 'normal' }}>Invoice management</Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel>
                            <SimpleGrid textAlign={'start'} columns={[1, 2, 2, 2, 2]} spacing={6}>
                                <Stack p={4} borderRadius={6} bgColor={'gray.50'} maxW={'400px'}>
                                    <Image w={'full'} src={DashboardImage} />
                                    <Text textTransform={'uppercase'} color={'green.500'} fontSize={'xs'} fontWeight={'medium'}>Reporting management</Text>
                                    <Text color={'gray.900'} fontWeight={'medium'}>Dashboard</Text>
                                    <Text color={'gray.500'} fontWeight={'light'} fontSize={'xs'}>View all the management analytics in a single page. Summary of payment, invoices and tasks.</Text>
                                </Stack>
                                <Stack p={4} borderRadius={6} bgColor={'green.900'} maxW={'400px'}>
                                    <Image w={'full'} src={TaskPage} />
                                    <Text textTransform={'uppercase'} color={'green.500'} fontSize={'xs'} fontWeight={'medium'}>Reporting management</Text>
                                    <Text color={'white'} fontWeight={'medium'}>Tasks</Text>
                                    <Text color={'gray.300'} fontWeight={'light'} fontSize={'xs'}>View of you summarized statistics of tasks with detailed table underneath for more information.</Text>
                                </Stack>
                                <Stack p={4} borderRadius={6} bgColor={'green.900'} maxW={'400px'}>
                                    <Image w={'full'} src={AddTaskPage} />
                                    <Text textTransform={'uppercase'} color={'green.500'} fontSize={'xs'} fontWeight={'medium'}>Reporting management</Text>
                                    <Text color={'white'} fontWeight={'medium'}>Add Task</Text>
                                    <Text color={'gray.300'} fontWeight={'light'} fontSize={'xs'}>New task form detail that will be sent to the selected company of your choice, you wanna refer the task to.</Text>
                                </Stack>
                            </SimpleGrid>
                        </TabPanel>
                        <TabPanel>
                            <SimpleGrid textAlign={'start'} columns={[1, 2, 2, 2, 2]} spacing={6} mt={6}>
                                <Stack p={4} borderRadius={6} bgColor={'white'} maxW={'400px'}>
                                    <Image w={'full'} src={PaidAmount} />
                                    <Text textTransform={'uppercase'} color={'green.500'} fontSize={'xs'} fontWeight={'medium'}>Payments</Text>
                                    <Text color={'gray.900'} fontWeight={'medium'}>Card details</Text>
                                    <Text color={'gray.500'} fontWeight={'light'} fontSize={'xs'}>View your card details and the account paid/accumulated since refering for the specific company.</Text>
                                </Stack>
                                <Stack p={4} borderRadius={6} bgColor={'green.900'} maxW={'400px'}>
                                    <Image w={'full'} src={PotentialAmount} />
                                    <Text textTransform={'uppercase'} color={'green.500'} fontSize={'xs'} fontWeight={'medium'}>Payments</Text>
                                    <Text color={'white'} fontWeight={'medium'}>Potential Amount</Text>
                                    <Text color={'gray.300'} fontWeight={'light'} fontSize={'xs'}>This is the amount of paid and unpaid tasks to your banking or prefered payment channel.</Text>
                                </Stack>
                                <Stack p={4} borderRadius={6} bgColor={'green.900'} maxW={'400px'}>
                                    <Image w={'full'} src={BankingDetailsUpdate} />
                                    <Text textTransform={'uppercase'} color={'green.500'} fontSize={'xs'} fontWeight={'medium'}>Payments</Text>
                                    <Text color={'white'} fontWeight={'medium'}>Update Banking Details</Text>
                                    <Text color={'gray.300'} fontWeight={'light'} fontSize={'xs'}>You can update your banking details anytime you want and you'rll receive it on that new card.</Text>
                                </Stack>
                            </SimpleGrid>
                        </TabPanel>
                        <TabPanel>
                            <SimpleGrid textAlign={'start'} columns={[1, 2, 2, 2, 2]} spacing={6}>
                                <Stack p={4} borderRadius={6} bgColor={'gray.50'} maxW={'400px'}>
                                    <Image w={'full'} src={InvoicePage} />
                                    <Text textTransform={'uppercase'} color={'green.500'} fontSize={'xs'} fontWeight={'medium'}>Invoice management</Text>
                                    <Text color={'gray.900'} fontWeight={'medium'}>Invoices</Text>
                                    <Text color={'gray.500'} fontWeight={'light'} fontSize={'xs'}>You can see all your detailed invoices in table with all statuses.</Text>
                                </Stack>
                            </SimpleGrid>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </VStack>
        </Stack>
    );
};