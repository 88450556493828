import { Heading, Image, Stack, Tag, Text, VStack } from '@chakra-ui/react';

import DashboardImage from '../theme/assets/transferal_dashboard.png';
import React from 'react';

export const HeroSection = (props: { id?: string; }) => {
    const { id } = props;
    return (
        <Stack pt={8} alignItems={'center'} bg={'#ebf3f1'} id={id || 'hero-section'}>
            <VStack maxW={'800px'} py={6}>
                <Tag bgColor={'#ecfed4'} my={4} p={2} borderRadius={12}>Whatever your size, sector or stage of growth.🫰</Tag>
                <Heading
                    fontSize={'7xl'}
                    fontWeight={'normal'}
                    textAlign={'center'}
                >
                    The modern <Text display={'inline'} fontWeight={'semibold'} bgGradient='linear(to-r, #1b5e20, #a5d6a7)' bgClip='text'>Referal</Text> platform. All your business in one app.
                </Heading>
                <Text my={6} maxW={'500px'} textAlign={'center'} color={'gray.500'}>Keep your business account needs safely organized under one roof. Manage money quickly, easily & efficiently.</Text>
            </VStack>
            <Stack maxW={'1000px'} p={1} bgColor={'#1a202c'} borderRadius={6}>
                <Image src={DashboardImage} />
            </Stack>
        </Stack>
    );
};