import {
    Box,
    Button,
    Flex,
    HStack,
    IconButton,
    Image,
    Link,
    Stack,
    useDisclosure,
} from '@chakra-ui/react';
import { CloseIcon, HamburgerIcon } from '@chakra-ui/icons';

import Logo from '../theme/assets/transferal_logo.png';
import { ReactNode } from 'react';

const NavLink = ({ children, onClick }: { children: ReactNode; onClick?: () => void; }) => (
    <Link
        px={2}
        py={1}
        rounded={'md'}
        _hover={{
            textDecoration: 'none',
            fontWeight: 'bold',
        }}
        onClick={onClick}
        >
        {children}
    </Link>
);

export const Navbar = () => {

    const { isOpen, onOpen, onClose } = useDisclosure();

    const hero: any = document.getElementById('hero-section' || '');
    const about: any = document.getElementById('about-section' || '');
    const features: any = document.getElementById('features-section' || '');
    const team: any = document.getElementById('team-section' || '');
    const contactUs: any = document.getElementById('contact-us-section' || '');

    return (
        <>
            <Box bg={'#ebf3f1'} px={4} id={'navbar-section'}>
                <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
                    <IconButton
                        size={'md'}
                        icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
                        aria-label={'Open Menu'}
                        display={{ md: 'none' }}
                        onClick={isOpen ? onClose : onOpen}
                    />
                    <HStack spacing={8} alignItems={'center'}>
                        <Box>
                            <Image src={Logo} height={'40px'} />
                        </Box>
                        <HStack
                            as={'nav'}
                            spacing={4}
                            display={{ base: 'none', md: 'flex' }}
                        >
                            <NavLink onClick={() => hero.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })}>Home</NavLink>
                            <NavLink onClick={() => about.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })}>About</NavLink>
                            <NavLink onClick={() => features.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })}>Features</NavLink>
                            <NavLink onClick={() => team.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })}>Team</NavLink>
                            <NavLink onClick={() => contactUs.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })}>Contact Us</NavLink>
                        </HStack>
                    </HStack>
                    <Flex alignItems={'center'}>
                        <Stack
                            flex={{ base: 1, md: 0 }}
                            justify={'flex-end'}
                            direction={'row'}
                        >
                            <Button
                                as={'a'}
                                fontSize={'sm'}
                                variant={'outline'}
                                colorScheme={'green'}
                                onClick={() => window.open('https://dashboard.transferal.co.za/login', '_blank')}
                            >
                                Sign In
                            </Button>
                            <Button
                                as={'a'}
                                display={{ base: 'none', md: 'inline-flex' }}
                                fontSize={'sm'}
                                colorScheme={'green'}
                                onClick={() => window.open('https://dashboard.transferal.co.za/register', '_blank')}
                            >
                                Sign Up
                            </Button>
                        </Stack>
                    </Flex>
                </Flex>

                {isOpen ? (
                    <Box pb={4} display={{ md: 'none' }}>
                        <Stack as={'nav'} spacing={4}>
                        <NavLink onClick={() => hero.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })}>Home</NavLink>
                            <NavLink onClick={() => about.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })}>About</NavLink>
                            <NavLink onClick={() => features.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })}>Features</NavLink>
                            <NavLink onClick={() => team.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })}>Team</NavLink>
                            <NavLink onClick={() => contactUs.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })}>Contact Us</NavLink>
                        </Stack>
                    </Box>
                ) : null}
            </Box>
        </>
    );
}