import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Divider, Heading, SimpleGrid, Stack } from '@chakra-ui/react';

import React from 'react';

export const FAQ = (props: { id?: string; }) => {
    const { id } = props;
    return (
        <Stack bg={'#f8f9fd'} py={16} alignItems={'center'} id={id || 'faq-section'}>
            <SimpleGrid maxW={'1000px'} columns={[1, 1, 2, 2, 2]} spacing={6}>
                <Stack>
                    <Heading fontWeight={'normal'}>Frequently Asked Questions about Transferal.</Heading>
                </Stack>
                <Stack>
                    <Accordion defaultIndex={[0]} allowMultiple>
                        <AccordionItem bg={'white'} borderTop={'none'} mb={3} borderRadius={6}>
                            <h2>
                                <AccordionButton minH={'50px'}>
                                    <Box as="span" flex='1' textAlign='left' fontWeight={'medium'}>
                                        What is Transferal?
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4} color={'gray.500'}>
                                <Divider mb={3} mt={'-1.5'} />
                                <strong style={{ color: 'green' }}>Transferal</strong> is a referal management system that help small delivery companies that have partnered with <strong style={{ color: '#ffc107' }}>Trucklivery</strong> Fleet management system. We make sure that everyone benefit fairly and be happy with the service that <strong style={{ color: 'green' }}>Transferal</strong> provide.
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem bg={'white'} border={'none'} mb={3} borderRadius={6}>
                            <h2>
                                <AccordionButton minH={'50px'}>
                                    <Box as="span" flex='1' textAlign='left' fontWeight={'medium'}>
                                        Who can create a Transferal account?
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4} color={'gray.500'}>
                                <Divider mb={3} mt={'-1.5'} />
                                Good news is that, anyone can use <strong>Transferal</strong> for their preferred company, and it can be more than one company.
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem bg={'white'} border={'none'} mb={3} borderRadius={6}>
                            <h2>
                                <AccordionButton minH={'50px'}>
                                    <Box as="span" flex='1' textAlign='left' fontWeight={'medium'}>
                                        How do people get paid?
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4} color={'gray.500'}>
                                <Divider mb={3} mt={'-1.5'} />
                                The company and referer will have to have an agreement on how they make payment between the two parties. It can be EFT, eWallet, moneySend, cash and etc. <strong style={{ color: 'green' }}>Transferal</strong> won't be part of their agreement and we don't accept and take any amount from referer and company. We're here to help, not to exploit.
                            </AccordionPanel>
                        </AccordionItem>
                    </Accordion>
                </Stack>
            </SimpleGrid>
        </Stack>
    );
};