import { Button, FormControl, FormLabel, HStack, Heading, Input, SimpleGrid, Stack, Tag, Text, Textarea, VStack } from '@chakra-ui/react';

import { FiMapPin } from 'react-icons/fi';
import { PhoneIcon } from '@chakra-ui/icons';
import React from 'react';

export const ContactUs = (props: { id?: string; }) => {
    const { id } = props;
    return (
        <Stack py={8} alignItems={'center'} bg={'white'} id={id || 'contact-us-section'}>
            <VStack py={6}>
                <Tag bgColor={'#ecfed4'} my={4} p={2} borderRadius={12}>Contact us</Tag>
                <Heading fontWeight={'regular'}>Reach out to us.</Heading>
                <Text maxW={'450px'} textAlign={'center'} color={'gray.500'} my={3}>Let's talk about what you want and we'll get back to you as soon as possible.</Text>

                <SimpleGrid mt={16} maxW={'1000px'} columns={[1, 1, 2, 2, 2]} spacing={6}>
                    <Stack spacing={6}>
                        <HStack spacing={3} maxW={'300px'}>
                            <FiMapPin size={'24px'} style={{ marginTop: '4px', alignSelf: 'start', color: '#4caf50' }} />
                            <Stack>
                                <Text textTransform={'capitalize'} fontWeight={'bold'} fontSize={'lg'}>our location</Text>
                                <Text textTransform={'capitalize'} color={'gray.500'}>13th Road, Noordwyk, midrand, south africa</Text>
                            </Stack>
                        </HStack>
                        <HStack spacing={3} maxW={'300px'}>
                            <PhoneIcon mt={1.5} alignSelf={'start'} color={'green.500'} />
                            <Stack>
                                <Text textTransform={'capitalize'} fontWeight={'bold'} fontSize={'lg'}>Contact details</Text>
                                <Text textTransform={'capitalize'} color={'gray.500'}><a href='tel:0761958601'>076 195 8601</a></Text>
                                <Text color={'gray.500'}><a href='mailto:support@transferal.com'>support@transferal.com</a></Text>
                            </Stack>
                        </HStack>
                    </Stack>

                    <Stack shadow={'md'} p={6} borderRadius={6}>
                        <FormControl>
                            <FormLabel>First name</FormLabel>
                            <Input
                                placeholder='John'
                                type='text'
                                colorScheme={'green'}
                                focusBorderColor='#4caf50'
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Last name</FormLabel>
                            <Input
                                placeholder='Doe'
                                type='text'
                                colorScheme={'green'}
                                focusBorderColor='#4caf50'
                            />
                        </FormControl>
                        <SimpleGrid columns={[1, 1, 1, 2, 2, 2]} spacing={4}>
                            <FormControl>
                                <FormLabel>Phone</FormLabel>
                                <Input
                                    placeholder='0712345789'
                                    type='text'
                                    colorScheme={'green'}
                                    focusBorderColor='#4caf50'
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Email</FormLabel>
                                <Input
                                    placeholder='john@doe.com'
                                    type='email'
                                    colorScheme={'green'}
                                    focusBorderColor='#4caf50'
                                />
                            </FormControl>
                        </SimpleGrid>
                        <FormControl>
                            <FormLabel>Subject</FormLabel>
                            <Input
                                placeholder='Subject'
                                type='text'
                                colorScheme={'green'}
                                focusBorderColor='#4caf50'
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Subject</FormLabel>
                            <Textarea
                                placeholder='Tell us more about...'
                                colorScheme={'green'}
                                focusBorderColor='#4caf50'
                            />
                        </FormControl>
                        <Button w={'full'} mt={3} colorScheme={'green'}>Send</Button>
                    </Stack>
                </SimpleGrid>
            </VStack>
        </Stack>
    );
};