import './App.css';

import { About, ContactUs, FAQ, Features, Footer, HeroSection, Navbar, Team } from './components';
import { Button, HStack, Heading, IconButton, Image, Stack, Text, VStack } from '@chakra-ui/react';

import { ExternalLinkIcon } from '@chakra-ui/icons';
import { FiChevronUp } from 'react-icons/fi';
import React from 'react';
import TruckliveryLogo from './theme/assets/trucklivery_gray_logo.png';
import { inject } from '@vercel/analytics';

function App() {
  const hero: any = document.getElementById('navbar-section' || '');

  inject();

  return (
    <>
      <Navbar />
      <HeroSection />
      <Stack py={8} alignItems={'center'} bgColor={'green.800'}>
        <VStack maxW={'800px'} py={6}>
          <Heading color={'white'}>Trusted By</Heading>
          <Text mb={6} maxW={'500px'} textAlign={'center'} color={'white'}>The fastest-growing companies use <span style={{ fontWeight: '400' }}>Transferal.</span></Text>
          <HStack>
            <Image maxH={'50px'} src={TruckliveryLogo} />
          </HStack>
        </VStack>
      </Stack>
      <About />
      <Features />
      <Team />
      <Stack py={8} alignItems={'center'} bgColor={'gray.900'}>
        <VStack maxW={'800px'} py={6}>
          <Text mt={6} maxW={'600px'} fontSize={'3xl'} textAlign={'center'} color={'white'} fontWeight={'semibold'}>Sign up in a minutes. Join the movement.</Text>
          <Text maxW={'700px'} textAlign={'center'} color={'gray.400'}><span style={{ fontWeight: '400' }}>Transferal</span> goal is to protect what's most precious to enterpreneuts: their time, energy and focus. We are ceating the future of business banking and finance, and we'd love you to join us.</Text>
          <Button mt={3} colorScheme={'green'} bgColor={'#ecfed4'} color={'gray.900'} rightIcon={<ExternalLinkIcon />} onClick={() => window.open('https://dashboard.transferal.co.za/register', '_blank')}>Open an account</Button>
          <Stack>
          </Stack>
        </VStack>
      </Stack>
      <FAQ />
      <ContactUs />
      <Footer />
      <IconButton
        colorScheme='green'
        shadow={'lg'}
        size={'lg'}
        aria-label='Search database'
        icon={<FiChevronUp />}
        onClick={() => hero.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })}
        borderRadius={'full'}
        style={{
          position: 'fixed',
          right: '50px',
          bottom: '50px'
        }}
      />
    </>
  );
}

export default App;
