import { Avatar, Heading, SimpleGrid, Stack, Tag, Text, VStack } from '@chakra-ui/react';

import React from 'react';

export const Team = (props: { id?: string; }) => {
    const { id } = props;
    return (
        <Stack py={8} alignItems={'center'} bg={'white'} id={id || 'team-section'}>
            <VStack py={6}>
                <Tag bgColor={'#ecfed4'} my={4} p={2} borderRadius={12}>Team</Tag>
                <Heading fontWeight={'regular'}>Meet the Team</Heading>
                <Text maxW={'450px'} textAlign={'center'} color={'gray.500'} my={3}>With tools to make every part of your process more human and a support team excited to help you.</Text>

                <SimpleGrid columns={[1, 2, 3, 3, 3]} my={16} spacing={24}>
                    <Stack alignItems={'center'}>
                        <Avatar size='xl' borderRadius={6} src='https://st4.depositphotos.com/3265223/21026/v/600/depositphotos_210263700-stock-illustration-default-avatar-photo-placeholder-profile.jpg' />
                        <Text textTransform={'capitalize'} fontSize={'lg'} fontWeight={'semibold'} mt={3}>anonymous</Text>
                        <Text textTransform={'capitalize'} color={'gray.500'}>head of creative director</Text>
                    </Stack>
                    <Stack alignItems={'center'}>
                        <Avatar size='xl' borderRadius={6} src='https://st4.depositphotos.com/3265223/21281/v/600/depositphotos_212811214-stock-illustration-profile-placeholder-default-avatar.jpg' />
                        <Text textTransform={'capitalize'} fontSize={'lg'} fontWeight={'semibold'} mt={3}>anonymous</Text>
                        <Text textTransform={'capitalize'} color={'gray.500'}>director of activation & strategy</Text>
                    </Stack>
                    <Stack alignItems={'center'}>
                        <Avatar size='xl' borderRadius={6} src='https://st4.depositphotos.com/3265223/21026/v/600/depositphotos_210263700-stock-illustration-default-avatar-photo-placeholder-profile.jpg' />
                        <Text textTransform={'capitalize'} fontSize={'lg'} fontWeight={'semibold'} mt={3}>anonymous</Text>
                        <Text textTransform={'capitalize'} color={'gray.500'}>director of product & design</Text>
                    </Stack>
                    <Stack alignItems={'center'}>
                        <Avatar size='xl' borderRadius={6} src='https://media.licdn.com/dms/image/C5103AQHVU1J59pM6PQ/profile-displayphoto-shrink_800_800/0/1516855183205?e=2147483647&v=beta&t=F2rZs0Zg-SyVyo2DHL1gCZb9htf8xzhPoxkooiOOaLc' />
                        <Text textTransform={'capitalize'} fontSize={'lg'} fontWeight={'semibold'} mt={3}>given danguru</Text>
                        <Text textTransform={'capitalize'} color={'gray.500'}>software engineer</Text>
                    </Stack>
                </SimpleGrid>
            </VStack>
        </Stack>
    );
};