import { Container, HStack, SimpleGrid, Stack, Text } from '@chakra-ui/react';
import { FiFacebook, FiLinkedin, FiTwitter } from 'react-icons/fi';

import React from 'react';

export const Footer = () => {
    return (
        <Stack bg={'green.900'} color={'white'} p={4}>
            <Container w={'auto'}>
                <SimpleGrid columns={[1, 1, 1, 1, 1]} spacing={6}>
                    <Stack>
                        <Text textAlign={'center'}>Transferal Ltd Pty. &copy; {new Date().getFullYear()}. All Rights Reserved.</Text>
                    </Stack>
                    <HStack justifyContent={'center'}>
                        <FiFacebook />
                        <FiLinkedin />
                        <FiTwitter />
                    </HStack>
                </SimpleGrid>
            </Container>
        </Stack>
    );
};