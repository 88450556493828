import { Container, HStack, Heading, SimpleGrid, Stack, Tag, Text } from '@chakra-ui/react';

import React from 'react';

export const About = (props: { id?: string; }) => {
    const { id } = props;
    return (
        <Stack py={8} bg={'white'} id={id || 'about-section'}>
            <Container maxW={'1000px'}>
                <Stack py={6}>
                    <Tag bgColor={'#ecfed4'} my={4} p={2} borderRadius={12} w={'max-content'}>About us</Tag>
                    <Heading fontWeight={'regular'}>All your referal modules in one app</Heading>
                    <Text maxW={'450px'} color={'gray.500'} my={3}>We have a range of price plans to match your needs, so you can start small and scale up.</Text>
                </Stack>
                <SimpleGrid columns={[1, 2, 2, 3, 3]} spacing={6}>
                    <HStack bgColor={'gray.50'} p={4} borderRadius={6}>
                        <Stack>
                            <Text fontSize={'lg'} fontWeight={'semibold'} color={'green.500'}>Custom rewards</Text>
                            <Text color={'gray.500'}>Send rewards to customers who have made successful referrals. Choose from cash rewards, store discounts, or custom gifts.</Text>
                        </Stack>
                    </HStack>
                    <HStack bgColor={'gray.50'} p={4} borderRadius={6}>
                        <Stack>
                            <Text fontSize={'lg'} fontWeight={'semibold'} color={'green.500'}>Pay per performance</Text>
                            <Text color={'gray.500'}>Pay commissions for new referral sales - we succeed only when you succeed. Our pricing is not based on the number of customers enrolled or your store revenue.</Text>
                        </Stack>
                    </HStack>
                    <HStack bgColor={'gray.50'} p={4} borderRadius={6}>
                        <Stack>
                            <Text fontSize={'lg'} fontWeight={'semibold'} color={'green.500'}>See your results at a glance</Text>
                            <Text color={'gray.500'}>Get access to key metrics such as revenue from referrals, number of customers who join your referral program.</Text>
                        </Stack>
                    </HStack>
                </SimpleGrid>
            </Container>
        </Stack>
    );
};